<template>
	<div class="helppage">
		<div class="helphead" @click="tohome">
			<div class="title1">逸教课堂</div>
			<div class="title2">帮助中心</div>
		</div>
		<el-divider></el-divider>
		<div class="helpheada">
			<div class="mark"></div>
			<div class="title2">帮助中心</div>
		</div>
		<div class="helpcotent">
			<div class="item-left">
				<div v-for="(item,index) in data" :key="index">
					<div class="moduleTitle mr-B">{{item.question_type}}</div>
					<div v-for="(items,indexa) in item.child_list" :key="indexa" :class="['moduleText',choose==items.id?'moduleText1':'']" @click="changeTabs(items.id)">{{items.title}}</div>
					<el-divider v-if="index<data.length-1"></el-divider>
				</div>
			</div>
			<div class="item-right">
				<div class="item-r-head">
					<div>{{dataabout.title}}</div>
				</div>
				<div class="item-r-content" v-html="dataabout.content">
				</div>
			</div>
		</div>
		<vbottom></vbottom>
	</div>
</template>

<script>
	import vbottom from '../common/bottom.vue';
	export default {
		components: {
			vbottom
		},
		data() {
			return {
				choose: 1,
				data:[],
				dataabout:{}
			}
		},
		mounted() {
			this.getdata()
		},
		methods: {
			tohome() {
				this.$router.push({
					path: '/home'
				})
			},
			//帮助列
			getdata(){
				this.$get("admin/index/gethelplist", {
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data.helpList
						this.changeTabs(response.data.helpList[0].child_list[0].id)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			//帮助详情
			gethelp(a){
				this.$get("admin/index/gethelpinfo", {
					id:a
				}).then(response => {
					if (response.error == 0) {
						this.dataabout = response.data.article
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			changeTabs(a) {
				this.choose = a
				this.gethelp(a)
			}
		}
	}
</script>

<style scoped>
	.helphead {
		width: 100%;
		height: 100px;
		line-height: 100px;
	}

	.helpheada {
		width: 100%;
		height: 50px;
		line-height: 50px;
	}

	.page-item {
		width: 100%;
		height: 100px;
		padding-left: 10%;
	}

	.title1 {
		float: left;
		font-size: 42px;
		font-family: HuXiaoBo-NanShen;
		font-weight: 400;
		color: #00D0B0;
		cursor: pointer;
		margin-left: 181px;
		line-height: 100px;
		margin-right: 15px;
	}

	.title2 {
		display: inline-block;
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #00D0B0;
		cursor: pointer;
		line-height: 100px;
	}

	.mark {
		width: 8px;
		height: 32px;
		background: #00D0B0;
		margin-right: 15px;
		margin-top: 34px;
		margin-left: 181px;
		float: left;
	}

	.item-left {
		width: 20%;
		height: 500px;
		overflow-y: scroll;
		padding-left: 10%;
	}

	.item-right {
		width: 70%;
		height: 500px;
	}

	.moduleTitle {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #323232;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.moduleText {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #646464;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	.moduleText1 {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #00D0B0;
		margin-left: 20px;
	}

	.moduleText:hover {
		cursor: pointer;
	}

	.mr-B {
		margin-bottom: 20px;
	}

	.heightauto {
		height: auto !important;
	}

	.helpcotent {
		height: 500px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.item-r-head {
		width: 100%;
		height: 60px;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #646464;
		line-height: 60px;
		border-bottom: 1px solid #C9C9C9;
	}

	.item-r-head :nth-child(1) {
		margin-left: 40px;

	}

	.item-r-content {
		width: 90%;
		margin-left: 5%;

		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #2D2D2C;
		line-height: 41px;

	}
</style>
